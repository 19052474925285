import { createContext, useEffect, useState } from 'react'
import { Web3Auth } from '@web3auth/modal'
import {
  getWalletConnectV2Settings,
  WalletConnectV2Adapter,
} from '@web3auth/wallet-connect-v2-adapter'

import { CHAIN_NAMESPACES, WEB3AUTH_NETWORK, WALLET_ADAPTERS } from '@web3auth/base'
import RPC from './web3RPC.ts' // for using web3.js
import { MetamaskAdapter } from '@web3auth/metamask-adapter'

import { toast } from 'react-toastify'

const clientId = process.env.REACT_APP_WEB3AUTH_CLIENT_ID
const chainIdNum = Number(process.env.REACT_APP_CHAINID_NUM)

const Web3AuthContext = createContext()

const flareConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: process.env.REACT_APP_CHAINID, // hex of 16   0x72 = 114
  rpcTarget: process.env.REACT_APP_RPC_TARGET,
  displayName: process.env.REACT_APP_DISPLAY_NAME,
  blockExplorer: process.env.REACT_APP_BLOCK_EXPLORER,
  ticker: process.env.REACT_APP_TICKER,
  tickerName: process.env.REACT_APP_TICKER_NAME,
}

// eslint-disable-next-line react/prop-types
export const Web3AuthContextProvider = ({ children }) => {
  const [web3auth, setWeb3auth] = useState(null)
  const [provider, setProvider] = useState(null)
  const [loggedIn, setLoggedIn] = useState(false)
  const [address, setAddress] = useState('')

  useEffect(() => {
    const init = async () => {
      try {
        localStorage.removeItem('Web3Auth-cachedAdapter')
        const web3auth = new Web3Auth({
          clientId,
          chainConfig: flareConfig,
          web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        })

        // adding metamask adapter
        const metamaskAdapter = new MetamaskAdapter({
          clientId,
          sessionTime: 3600, // 1 hour in seconds
          web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
          chainConfig: flareConfig,
        })
        // we can change the above settings using this function
        metamaskAdapter.setAdapterSettings({
          // sessionTime: 86400, // 1 day in seconds
          chainConfig: flareConfig,
          web3AuthNetwork: WEB3AUTH_NETWORK.SAPPHIRE_MAINNET,
        })

        // it will add/update  the metamask adapter in to web3auth class
        web3auth.configureAdapter(metamaskAdapter)

        const defaultWcSettings = await getWalletConnectV2Settings(
          CHAIN_NAMESPACES.EIP155,
          [chainIdNum],
          process.env.REACT_APP_WALLET_CONNECT_ID,
        )

        const walletConnectV2Adapter = new WalletConnectV2Adapter({
          adapterSettings: { ...defaultWcSettings.adapterSettings },
          loginSettings: { ...defaultWcSettings.loginSettings },
        })

        web3auth.configureAdapter(walletConnectV2Adapter)

        setWeb3auth(web3auth)

        await web3auth.initModal({
          modalConfig: {
            [WALLET_ADAPTERS.OPENLOGIN]: {
              label: 'openlogin',
              loginMethods: {
                // Disable facebook and reddit
                facebook: {
                  name: 'facebook',
                  showOnModal: false,
                },
                reddit: {
                  name: 'reddit',
                  showOnModal: false,
                },
                google: {
                  name: 'google',
                  showOnModal: false,
                },
                discord: {
                  name: 'discord',
                  showOnModal: false,
                },
                twitch: {
                  name: 'twitch',
                  showOnModal: false,
                },
                apple: {
                  name: 'apple',
                  showOnModal: false,
                },
                line: {
                  name: 'line',
                  showOnModal: false,
                },
                github: {
                  name: 'github',
                  showOnModal: false,
                },
                kakao: {
                  name: 'kakao',
                  showOnModal: false,
                },
                linkedin: {
                  name: 'linkedin',
                  showOnModal: false,
                },
                twitter: {
                  name: 'twitter',
                  showOnModal: false,
                },
                weibo: {
                  name: 'weibo',
                  showOnModal: false,
                },
                wechat: {
                  name: 'wechat',
                  showOnModal: false,
                },
                sms_passwordless: {
                  name: 'sms_passwordless',
                  showOnModal: false,
                },
              },
            }, //Disable Torus Plugin
            [WALLET_ADAPTERS.TORUS_EVM]: {
              label: 'TORUS_EVM',
              showOnModal: false,
            },
            [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
              label: 'WALLET_CONNECT_V2',
              showOnModal: true,
            },
          },
        })

        setProvider(web3auth.provider)
      } catch (error) {
        console.log('wallet--init--catch', error)
        toast.error('Web3auth not initialized yet, please reload your browser', {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: 'web3auth_toast',
        })
      }
    }

    init()
  }, [])

  const isConnected = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet--isConnected')
      return false
    }
    return web3auth.status === 'connected'
  }

  const web3Login = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet')
      return
    }
    const web3authProvider = await web3auth.connect()
    setProvider(web3authProvider.provider)
    const connectedStatus = web3auth.status === 'connected'
    if (connectedStatus) {
      setLoggedIn(true)
      await getImmediateAccounts(web3authProvider.provider)
    }
  }

  const web3logout = async () => {
    if (!web3auth) {
      console.log('web3auth not initialized yet')
      return
    }
    await web3auth.logout()
    setLoggedIn(false)

    const web3authProvider = await web3auth.logout()
    setProvider(web3authProvider)
    setAddress('')
    setLoggedIn(false)
  }

  const getImmediateAccounts = async (provider) => {
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const acc = await rpc.getAccounts()
    setAddress(acc)
    return acc
  }

  const getAccounts = async () => {
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const acc = await rpc.getAccounts()
    setAddress(acc)
    return acc
  }

  const getBalance = async () => {
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const balance = await rpc.getBalance()

    console.log('balance-->', balance)
    // uiConsole(balance)
    return balance
  }

  const createPreSale = async (contractAddress, Payload) => {
    console.log('createPreSale--')
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const receipt = await rpc.createPreSale(contractAddress, Payload)
    return receipt
  }

  const isSaleAvailable = async (contractAddress) => {
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    return rpc.isPreSaleAvailable(contractAddress)
  }

  const stopPreSale = async (contractAddress) => {
    console.log('stopPreSale--')
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const receipt = await rpc.stopPreSale(contractAddress)
    return receipt
  }

  const createAllowList = async (contractAddress, Payload) => {
    console.log('createAllowList--')
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const receipt = await rpc.createAllowList(contractAddress, Payload)
    return receipt
  }

  const isAllowListAvailable = async (contractAddress) => {
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    return rpc.isAllowListAvailable(contractAddress)
  }

  const stopAllowList = async (contractAddress) => {
    console.log('stopAllowList--')
    if (!provider) {
      console.log('web3auth not initialized yet')
      return
    }
    const rpc = new RPC(provider)
    const receipt = await rpc.stopAllowList(contractAddress)
    return receipt
  }

  return (
    <Web3AuthContext.Provider
      value={{
        loggedIn,
        isConnected,
        web3auth,
        web3Login,
        web3logout,
        getAccounts,
        address,
        getBalance,
        createPreSale,
        isSaleAvailable,
        stopPreSale,
        createAllowList,
        isAllowListAvailable,
        stopAllowList,
      }}
    >
      {children}
    </Web3AuthContext.Provider>
  )
}

export default Web3AuthContext
