import type { IProvider } from '@web3auth/base'
import Web3 from 'web3'

const abiData = import('../constants/abi')

export default class RPC {
  private provider: IProvider

  constructor(provider: IProvider) {
    this.provider = provider
  }

  async getChainId(): Promise<string> {
    try {
      const web3 = new Web3(this.provider as any)

      // Get the connected Chain's ID
      const chainId = await web3.eth.getChainId()

      return chainId.toString()
    } catch (error) {
      return error as string
    }
  }

  async getAccounts(): Promise<any> {
    try {
      const web3 = new Web3(this.provider as any)
      // Get user's Ethereum public address
      const address = await web3.eth.getAccounts()
      return address && address.length > 0 ? address[0] : ''
    } catch (error) {
      return ''
    }
  }

  async getBalance(): Promise<string> {
    try {
      // console.log(" I am called--getBalance");
      const web3 = new Web3(this.provider as any)

      // Get user's Ethereum public address
      const address = await this.getAccounts()
      // console.log(web3.utils.toChecksumAddress(address));
      // Get user's balance in ether
      const balance = web3.utils.fromWei(
        await web3.eth.getBalance(address), // Balance is in wei
        'ether',
      )
      return balance
    } catch (error) {
      return ''
    }
  }

  async createPreSale(contractAddress: any, dataObj: any) {
    try {
      console.log('writeContract--')
      const web3 = new Web3(this.provider as any)

      const { contractABI } = await abiData

      const myContract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )

      const { supply, price, startDate, endDate } = dataObj
      console.log(myContract)

      const _startDt = new Date(startDate).getTime()
      const _endDt = new Date(endDate).getTime()

      const account = await web3.eth.getAccounts()
      if (!account) {
        throw new Error('Connect your wallet')
      }

      const receipt = await myContract.methods
        .createPresale(supply, price, _startDt, _endDt)
        .send({ from: `${account[0]}` })

      console.log(receipt)
      return { status: 'success', receipt: receipt }
    } catch (error) {
      console.log('writeContract--catch--', error)
      return { status: 'failed', receipt: null }
    }
  }

  async isPreSaleAvailable(contractAddress: string | undefined) {
    try {
      const web3 = new Web3(this.provider as any)
      const { contractABI } = await abiData
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )

      // Read message from smart contract
      const message = await contract.methods.isPresaleActive().call()
      console.log('isPreSaleAvailable--', message)
      return message
    } catch (error) {
      return error as string
    }
  }

  async getPreSalePrice(contractAddress: string | undefined) {
    try {
      if (contractAddress) {
        const web3 = new Web3(this.provider as any)
        const { contractABI } = await abiData
        const contract = new web3.eth.Contract(
          JSON.parse(JSON.stringify(contractABI)),
          contractAddress,
        )

        // Read message from smart contract
        const message = await contract.methods.getMintPrice().call()
        const price = Web3.utils.fromWei(message, 'ether')
        console.log('getPreSalePrice--', price)

        return price
      } else {
        throw new Error('Contract Address is missing')
      }
    } catch (error) {
      console.log('--getPreSalePrice--', error)
      return error as string
    }
  }

  async mintNFT(contractAddress: any) {
    try {
      console.log('mintNFT--')
      const web3 = new Web3(this.provider as any)

      const { contractABI } = await abiData

      const myContract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )

      const amount = web3.utils.toHex(web3.utils.toWei('4', 'ether'))

      const data = myContract.methods.mint(1).encodeABI() // change amountwei  to tokens

      const account = await web3.eth.getAccounts()
      if (!account) {
        throw new Error('Connect your wallet')
      }

      const txObject = {
        from: `${account[0]}`,
        to: contractAddress,
        data,
        value: amount,
      }

      const receipt = await web3.eth.sendTransaction(txObject)

      console.log(receipt)
      return receipt
    } catch (error) {
      console.log('mintNFT--catch--', error)
      return error as string
    }
  }

  async getUserMintLimit(contractAddress: string | undefined, userAccount: string | undefined) {
    try {
      if (contractAddress && userAccount) {
        const web3 = new Web3(this.provider as any)
        const { contractABI } = await abiData
        const contract = new web3.eth.Contract(
          JSON.parse(JSON.stringify(contractABI)),
          contractAddress,
        )

        // Read message from smart contract
        const message = await contract.methods.getUserMintLimit(userAccount, 1).call()
        console.log('getUserMintLimit--', message)

        return message
      } else {
        throw new Error('Contract Address or User Account is missing')
      }
    } catch (error) {
      console.log('--getUserMintLimit--', error)
      return error as string
    }
  }
  async getUserNFTBalance(contractAddress: string | undefined, userAccount: string | undefined) {
    try {
      if (contractAddress && userAccount) {
        const web3 = new Web3(this.provider as any)
        const { contractABI } = await abiData
        const contract = new web3.eth.Contract(
          JSON.parse(JSON.stringify(contractABI)),
          contractAddress,
        )

        // Read message from smart contract
        const message = await contract.methods.balanceOf(userAccount, 1).call()
        console.log('getUserNFTBalance--', message)

        return message
      } else {
        throw new Error('Contract Address or User Account is missing')
      }
    } catch (error) {
      console.log('--getUserNFTBalance--', error)
      return error as string
    }
  }

  async stopPreSale(contractAddress: any) {
    try {
      console.log('stopPreSale--')
      const web3 = new Web3(this.provider as any)

      const { contractABI } = await abiData

      const myContract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )
      const account = await web3.eth.getAccounts()
      if (!account) {
        throw new Error('Connect your wallet')
      }
      const receipt = await myContract.methods.closePresale().send({ from: `${account[0]}` })

      console.log(receipt)
      return { status: 'success', receipt: receipt }
    } catch (error) {
      console.log('stopPreSale--catch--', error)
      return { status: 'failed', receipt: null }
    }
  }

  async createAllowList(contractAddress: any, dataObj: any) {
    try {
      console.log('createAllowList--')
      const web3 = new Web3(this.provider as any)

      const { contractABI } = await abiData

      const myContract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )

      const { supply, price, startDate, endDate } = dataObj

      const _startDt = new Date(startDate).getTime()
      const _endDt = new Date(endDate).getTime()

      const account = await web3.eth.getAccounts()
      if (!account) {
        throw new Error('Connect your wallet')
      }

      const receipt = await myContract.methods
        .setAllowList(supply, price, _startDt, _endDt)
        .send({ from: `${account[0]}` })

      return { status: 'success', receipt: receipt }
    } catch (error) {
      console.log('createAllowList--catch--', error)
      return { status: 'failed', receipt: null }
    }
  }

  async isAllowListAvailable(contractAddress: string | undefined) {
    try {
      const web3 = new Web3(this.provider as any)
      const { contractABI } = await abiData
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )

      // Read message from smart contract
      const message = await contract.methods.isAllowListActive().call()
      console.log('isPreSaleAvailable--', message)
      return message
    } catch (error) {
      return error as string
    }
  }

  async stopAllowList(contractAddress: any) {
    try {
      console.log('stopAllowList--')
      const web3 = new Web3(this.provider as any)

      const { contractABI } = await abiData

      const myContract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(contractABI)),
        contractAddress,
      )
      const account = await web3.eth.getAccounts()
      if (!account) {
        throw new Error('Connect your wallet')
      }

      const receipt = await myContract.methods.closeAllowList().send({ from: `${account[0]}` })

      console.log(receipt)
      return { status: 'success', receipt: receipt }
    } catch (error) {
      console.log('stopAllowList--catch--', error)
      return { status: 'failed', receipt: null }
    }
  }
}
